/* Estilo para o container principal */
.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

}

/* Estilo para o card */
.login-card {
  background-color: #fff;
  border-radius: 10px;
  padding: 2rem;
  width: 350px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  padding-top: 40px;
  padding-bottom: 40px;
}

/* Logo */
.login-logo {
  width: 250px;
  margin-bottom: 2rem;
}

/* Título */
.login-title {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1.5rem;
}

/* Estilo do formulário */
.login-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Grupo de inputs */
.input-group {
  position: relative;
  display: flex;
  align-items: center;
}

/* Estilo dos ícones */
.input-icon {
  position: absolute;
  left: 10px;
  color: #777;
}

/* Ícone à direita */
.input-icon-right {
  position: absolute;
  right: 10px;
  color: #777;
  cursor: pointer;
}

/* Estilo do input */
.login-input {
  width: 100%;
  padding: 10px 35px 10px 35px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  outline: none;
}

.login-input:focus {
  border-color: #0076BE;
}

/* Botão de login */
.login-button {
  background-color: #0076BE;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #005A94;
}


/* Grupo de inputs */
.input-group {
  position: relative;
  display: flex;
  align-items: center; /* Centraliza os itens verticalmente */
}

/* Estilo dos ícones */
.input-icon {
  position: absolute;
  left: 10px;
  color: #777;
  font-size: 1.2rem;
  display: flex;
  align-items: center; /* Garante alinhamento vertical */
  justify-content: center;
}

/* Ícone à direita */
.input-icon-right {
  position: absolute;
  right: 10px;
  color: #777;
  font-size: 1.2rem;
  display: flex;
  align-items: center; /* Garante alinhamento vertical */
  justify-content: center;
  cursor: pointer;
}

/* Estilo do input */
.login-input {
  width: 100%;
  padding: 10px 35px; /* Ajuste para caber os ícones sem deslocar o texto */
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  outline: none;
  box-sizing: border-box; /* Garante que o padding não afeta o tamanho do input */
}

.login-input:focus {
  border-color: #0076BE;
}

/* Botão de alternar visibilidade da senha */
.toggle-password-button {
  position: absolute;
  right: 10px;
  transform: translateY(-50%); /* Ajuste fino para centralização */
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: #777;
  font-size: 1.2rem;
  background-color: red;
}

.toggle-password-button:hover {
  color: #0076BE;
}

.bold {
  font-weight: bold;
  margin-top: -10px;
  cursor: pointer;
}


.bold:hover {

  color: #0076BE;
}