.card-task-principal {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin: 10px 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  cursor: pointer;
}


.status-lab{
  margin: 0; 
  color: #495053; 
  font-size: 14px;
   font-weight: normal;
}

.card-inf-lab{
  color: #898989;
}

.card-inf-lab-desc{
  color: #000
}

.card-inf-lab-div{
  width: 100%;
  margin-top: 5px;
  display: flex;
}

.card-inf-lab-div1{
  width: 100%;
}

.card-inf-lab-div2 {
  display: flex; /* Garante o alinhamento flexível */
  justify-content: flex-end; /* Move o conteúdo para a direita */
  align-items:end ; /* Alinha verticalmente (opcional) */
  padding-left: 4px;
}


.responsive-column-os{
    display: flex;
}


.responsive-container {
  align-items: center;
  background-color: #ffffff; /* Exemplo de cor */
  color: #000000;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  }
  
  .responsive-row {
    display: flex;
    flex-direction: row; /* Itens na mesma linha */
    align-items: center;
    flex: 1 1 100%; /* Ocupa toda a largura no modo responsivo */
    margin-bottom: 10px; /* Espaçamento entre linhas */
  }
  
  .responsive-icon {
    flex: 0 0 auto; /* Ícone com tamanho fixo */
    margin-right: 10px; /* Espaço entre o ícone e a coluna */
  }
  
  .responsive-column {
    flex: 1 1 auto; /* Coluna ocupa o espaço restante */
    text-align: left;
  }
  
  

  @media (max-width: 768px) {

    .status-lab{

      font-size: 12px;

    }
    .card-inf-lab-div{
      margin-top: 0px;
    }
  }