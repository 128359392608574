
  /* Container do cabeçalho */

  .menu-cursor{
    cursor: pointer;
  }

  .chamados-logo {
    width: 140px; /* Ajusta o tamanho do logotipo para telas menores */
    height: 60px;
  }

.card-qrcode{
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
background-color: #fff;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
}


/* Ícone */
.icon {
  font-size: 1.5rem;
  color: #0076BE; /* Azul */
}


.menu-icon {
  display: none; /* Oculta o ícone no desktop */
  font-size: 1.5rem;
  cursor: pointer;
  color: #495053;
}

/* Estilização das divs para ficarem lado a lado */
.form-group2 {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 10px; /* Espaçamento lateral entre os elementos */
}

/* Container que envolve os form-groups */
.chamados-form2 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px; /* Espaçamento entre as divs */
}


.container-cad1 {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 2fr;
  grid-template-areas: "content1 content2";
  grid-gap: 0.5rem;
}

#content1 {
  grid-area: content1;
  border-radius: var(--main-radius);
  padding-top: var(--main-padding);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

#content2 {
  grid-area: content2;
  border-radius: var(--main-radius);
  padding-top: var(--main-padding);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* Ícones padrão: escondidos */
.menu-icon-left {
  display: none; /* Oculta os ícones por padrão */

}


.btn-container {
  display: flex; /* Ativa o flexbox */
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
  margin-top: 30px; /* Adiciona espaçamento acima */
  margin-bottom: 30px; 
}

.btn-enviar {
  background: linear-gradient(to right, #0076BE, #003D80); /* Gradiente no botão */
  color: #fff; /* Cor do texto */
  border: none; /* Remove borda */
  border-radius: 10px; /* Arredonda os cantos */
  padding: 10px 20px; /* Espaçamento interno */
  font-size: 16px; /* Tamanho do texto */
  cursor: pointer; /* Mostra o cursor de clique */
  transition: all 0.3s ease; /* Animações suaves */
  width: 60%;
  font-weight: bolder;
}

.btn-enviar:hover {
  background: linear-gradient(to right, #003D80, #0076BE); /* Inverte gradiente no hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adiciona sombra */
}
  /* Responsividade */
  @media (max-width: 768px) {

    .btn-enviar {
      width: 60%; /* Ajusta para o conteúdo em telas maiores */
    }
    .menu-icon-left {
      display: inline-block; /* Exibe os ícones no modo responsivo */
      margin-right: 0.5rem; /* Adiciona espaçamento entre o ícone e o texto */
      font-size: 18px; /* Ajusta o tamanho dos ícones */
      color: #495053;
    }

    .chamados-form {
      flex-direction: column;
    }

  
    .icon {
      font-size: 1.2rem;
    }
  
    .line {
      margin: 0 0.5rem;
    }
  
  
 

  
    .chamados-menu {
      display: none; /* Oculta o menu no mobile */
      position: absolute;
      top: 60px; /* Abaixo do cabeçalho */
      right: 0;
      flex-direction: column;
      background-color: #fff;
      width: 100%;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      z-index: 1000;
      padding-top: 20px;
      padding-bottom: 40px;
      padding-left: 20px;
      padding-right: 20px;
    }
  
    .chamados-menu.active {
      display: flex; /* Exibe o menu quando ativo */
    }
  
    .chamados-menu a {
        font-size: 1rem; /* Garante tamanho uniforme do texto em telas pequenas */
       padding-bottom: 20px;
       padding-left: 15px;
      border-bottom: 1px solid #ddd;

    }
  
    .menu-icon {
      display: block; /* Exibe o ícone no mobile */
    }
  
    
  }
  

  @media only screen and (max-width: 700px) {

    .container-cad1 {
      grid-template-columns: 1fr;
      grid-template-areas:
        "content1"
        "content2"
        "content3"
        "content4";
      grid-gap: 0rem;
   
    }
  }