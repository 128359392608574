.icon-dash{
font-size: 150px
}

.lab-dash{
color: #fff;
margin-top: 10px;
margin-bottom: 30px;
}


@media (max-width: 500px) {

  .icon-dash{
    font-size: 90px
    }
    
    .lab-dash{
    font-size: 16px;
    }
    
}