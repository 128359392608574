
  
  .form-group-modal{
    margin-bottom: 10px;
  }
  
  .scrol-modal{
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    max-height: 460px;
    overflow-y: auto;
  overflow-x: hidden;
  }
  
  .title-modal-incluir{
    width: 100%;
    height: 30px;
    background-color: #0076BE; 
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    display: flex;
    justify-content: space-between;
  }
  
  .icone-modal-IncluirNovo {
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 22px;
    margin-right: 15px;
    color: rgb(255, 0, 0);
    margin-top: 4px;
  }
  
  .icone-modal-IncluirNovo:hover {
    color: rgb(255, 255, 255);
  }
  
  .modal-IncluirNovo-title{
    font-size: 16px;
    margin-top: 5px;
    margin-left: 10px;
    color: rgb(255, 255, 255);
  }
  
  .filtro-avancado-btn{
    width: 30px;
    height: 35px;
    margin-left: 5px;
    background-image: linear-gradient(to bottom, #fafafa, rgb(231, 229, 229));
    border: 1px solid  #92989e;
    border-radius: 5px;
    display: flex; /* Ativar layout de flexbox */
    flex-direction: column; /* Garantir que os elementos sejam empilhados em coluna */
    justify-content: center; /* Centralizar verticalmente */
    align-items: center; /* Centralizar horizontalmente */
    cursor: pointer;
  }
  
  .filtro-avancado-btn:hover{
    opacity: 0.7;
    }
    
  
  .filtro-avancado{
   
    width: 100%;
    display: flex;
  }
  
  .filtro-avancado-select{
    width: 100%;
  }
  
  .btn-filter{
    font-size: 14px;
   
  }
  
  .btn-filter-remover{
    font-size: 20px;
  }
  
  .btn-escolha{
    margin-bottom: 20px;
    margin-top: 10px;
  }
  
  /* Container principal */
  .chamados-container {
      display: flex;
      flex-direction: column;
      min-height: 100vh;
      background: linear-gradient(to bottom, #0076BE, #003D80);
      font-family: Arial, sans-serif;
    }
    
    /* Cabeçalho */
    .chamados-header {
      display: flex;
      align-items: center;
      justify-content: space-between; /* Espaço entre o logo e o menu */
      padding: 0.5rem 2rem;
      background-color: #fff;
      position: relative; /* Para centralizar o menu usando posição absoluta */
    }
    
    .chamados-logo {
      width: 150px;
    }
    
    .chamados-menu {
      position: absolute; /* Permite centralizar na horizontal */
      left: 50%; /* Move o menu para o centro */
      transform: translateX(-50%); /* Ajusta para centralização exata */
      display: flex;
      gap: 1.5rem; /* Espaçamento entre os links */
    }
    
    .chamados-menu a {
      text-decoration: none;
      color: #495053;
    }
    
    .chamados-menu a:hover {
      color: #0076BE;
    }
    
    /* Conteúdo principal */
    .chamados-main {
      flex: 1; /* Ocupar todo o espaço restante */
      display: flex; /* Ativar layout de flexbox */
      flex-direction: column; /* Garantir que os elementos sejam empilhados em coluna */
      justify-content: center; /* Centralizar verticalmente */
      align-items: center; /* Centralizar horizontalmente */
      gap: 20px; /* Espaçamento entre os elementos */
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
    }
  
    /* Criando a "ponta" no topo */
    .chamados-card::before {
      content: ""; /* Necessário para criar o pseudo-elemento */
      position: absolute; /* Posiciona relativo ao pai */
      top: -20px; /* Ajusta a altura para fora do topo */
      left: 50%; /* Centraliza horizontalmente */
      transform: translateX(-50%); /* Compensa o deslocamento para o centro */
      width: 40px; /* Largura da ponta (aumentado) */
      height: 40px; /* Altura da ponta (aumentado) */
      background-color: #fff; /* Mesmo fundo da div */
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%); /* Cria o formato triangular */
  
    }
    
    
    
    /* Card */
    .chamados-card {
      background-color: #fff;
      border-radius: 10px;
      width: 100%;
      max-width: 600px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      position: relative;
    }
    
    .form-chamados{
   
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 20px;
  
    }
    /* Cabeçalho do card */
    .card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 10px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    }
    
    .card-header h2{
      font-size: 20px;
      color: #000000;
    }
  
    .card-icon {
      font-size: 2rem;
      color: #0076BE;
    }
    
    .qrcode-icon {
      font-size: 40px;
      color: #000000;
      cursor: pointer;
    }
    
  
    /* Formulário */
    .chamados-form .form-group {
      margin-bottom: 8px;
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    
    .chamados-form .form-group label {
  
      margin-bottom: 0.5rem;
      color: #495053;
    }
    
    .chamados-form .form-group input,
    .chamados-form .form-group textarea {
      padding: 0.5rem;
      border: 1px solid #92989e;
      border-radius: 5px;
      font-size: 1rem;
     font-weight: normal;
  
    }
    
    .chamados-form .form-group input:focus,
    .chamados-form .form-group textarea:focus {
      border: 1px solid #000000;
      outline: none;
    }
    
    .inp-textarea {
      resize: none;
      height: 70px;
    }
    
    .header-container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      width: 40%;
      position: relative;
      
    }
    
  
  
  /* Linha lateral */
  .line {
    flex: 1;
    height: 2px;
    background: #ffffff; /* Branco */
    margin: 0 1rem;
  }
  
  /* Ícone circular */
  .icon-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff; /* Fundo branco */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 5px;
  }
  
  /* Ícone */
  .icon {
    font-size: 1.5rem;
    color: #0076BE; /* Azul */
  }
  
  
  .menu-icon {
    display: none; /* Oculta o ícone no desktop */
    font-size: 1.5rem;
    cursor: pointer;
    color: #495053;
  }
  
  /* Estilização das divs para ficarem lado a lado */
  .form-group2 {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0 10px; /* Espaçamento lateral entre os elementos */
  }
  
  /* Container que envolve os form-groups */
  .chamados-form2 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px; /* Espaçamento entre as divs */
  }
  
  
  .container-cad1 {
    display: grid;
    width: 100%;
    grid-template-columns: 2fr 2fr;
    grid-template-areas: "content1 content2";
    grid-gap: 0.5rem;
  }
  
  .container-cad2 {
    width: 100%;
  }
  
  #content1 {
    grid-area: content1;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  #content2 {
    grid-area: content2;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  /* Ícones padrão: escondidos */
  .menu-icon-left {
    display: none; /* Oculta os ícones por padrão */
  
  }
  
  
  .btn-container {
    display: flex; /* Ativa o flexbox */
    justify-content: center; /* Centraliza horizontalmente */
    align-items: center; /* Centraliza verticalmente */
    margin-top: 30px; /* Adiciona espaçamento acima */
    margin-bottom: 30px; 
  }
  
  .btn-enviar {
    background: linear-gradient(to right, #0076BE, #003D80); /* Gradiente no botão */
    color: #fff; /* Cor do texto */
    border: none; /* Remove borda */
    border-radius: 10px; /* Arredonda os cantos */
    padding: 10px 20px; /* Espaçamento interno */
    font-size: 16px; /* Tamanho do texto */
    cursor: pointer; /* Mostra o cursor de clique */
    transition: all 0.3s ease; /* Animações suaves */
    width: 60%;
    font-weight: bolder;
  }
  
  .btn-enviar:hover {
    background: linear-gradient(to right, #003D80, #0076BE); /* Inverte gradiente no hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adiciona sombra */
  }
  
  
  
  .react-tabs {
    border-radius: 10px;
    width: 100%;
    color: white;
    background: #ffffff;
   margin-top: 10px;
  }
  
  .react-tabs__tab-list {
    display: flex;
    width: 100%;
    margin: 0;
    padding: 0;
    color: white;
    background: #ffffff;
    margin-right: 30px;

  }
  
  .react-tabs__tab {
    height: 40px;
    list-style: none;
    cursor: pointer;
    color: #898989;
    font-weight: bold;
    width: 100%;
    font-size: 25px;
    text-align: center;
    justify-content: center; /* Centraliza horizontalmente */
    align-items: center; /* Centraliza verticalmente */
    display: flex;
  
  }
  
  .react-tabs__tab:hover {
   background-color: #fff;
  }
  
  .react-tabs__tab--selected {
    background-color: #fff;
    border-bottom: 3px solid #0076BE;
    color: #0076BE;
  }
  
  .react-tabs__tab-panel {
    display: none;
    width: 100%;
  
  }
  
  .react-tabs__tab-panel--selected {
    display: block;
  
  }
  
  .react-tabs__tab {
    padding-left: 24px;
  
  }
  
  .react-tabs__tab--selected {
    padding-left: 21px;
  }
  
  .panel-content {
    color: black;
    width: 100%;
    overflow-y: auto;
  }
  
  
    /* Responsividade */
    @media (max-width: 768px) {
  
      .qrcode-icon {
        font-size: 60px;
        color: #000000;
        cursor: pointer;
      }
    
      
      .header-container {
        width: 90%;
  
      }
  
  
      .btn-enviar {
        width: 60%; /* Ajusta para o conteúdo em telas maiores */
      }
      .menu-icon-left {
        display: inline-block; /* Exibe os ícones no modo responsivo */
        margin-right: 0.5rem; /* Adiciona espaçamento entre o ícone e o texto */
        font-size: 18px; /* Ajusta o tamanho dos ícones */
        color: #495053;
      }
  
      .chamados-form {
        flex-direction: column;
      }
  
      .icon-circle {
        width:90px;
        height: 90px;
      }
    
      .icon {
        font-size: 1.2rem;
      }
    
      .line {
        margin: 0 0.5rem;
      }
    
    
   
      .chamados-menu {
        display: none; /* Oculta o menu no mobile */
        position: absolute;
        top: 60px; /* Abaixo do cabeçalho */
        right: 0;
        flex-direction: column;
        background-color: #fff;
        width: 100%;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        z-index: 1000;
        padding-top: 20px;
        padding-bottom: 40px;
        padding-left: 20px;
        padding-right: 20px;
      }
    
      .chamados-menu.active {
        display: flex; /* Exibe o menu quando ativo */
      }
    
      .chamados-menu a {
          font-size: 1rem; /* Garante tamanho uniforme do texto em telas pequenas */
         padding-bottom: 20px;
         padding-left: 15px;
        border-bottom: 1px solid #ddd;
  
      }
    
      .menu-icon {
        display: block; /* Exibe o ícone no mobile */
      }
    
      .chamados-logo {
        width: 120px; /* Ajusta o tamanho do logotipo para telas menores */
      }
      
    }
    
  
    @media only screen and (max-width: 700px) {
  
      .container-cad1 {
        grid-template-columns: 1fr;
        grid-template-areas:
          "content1"
          "content2"
          "content3"
          "content4";
        grid-gap: 0rem;
     
      }
    }